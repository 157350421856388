import React, { useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { GetPageContentSingle, UpdatePageContent } from "../../../api/PagesApi";
import JoditEditor from "jodit-react";
import { toast } from "react-toastify";
import PageContentEditCss from "./css/PageContentEdit.module.css";
async function getData(id, setData) {
  let data = await GetPageContentSingle(id);
  setData(data);
}
const PageContentEdit = () => {
  const [pageContent, setPageContent] = useState({});
  const [searchParams] = useSearchParams();
  const content_id = searchParams.get("content_id");
  useEffect(() => {
    getData(content_id, setPageContent);
  }, [content_id]);
  const my_content = useRef();

  const config = {
    useSplitMode: false,
    toolbarSticky: true,
    toolbarStickyOffset: 51,
    uploader: {
      insertImageAsBase64URI: true,
    },
    placeholder: "Type Your Content Here...",
    showXPathInStatusbar: false,
    cleanHTML: {
      rules: {
        h1: {
          removeTags: ["span"], // Remove <span> inside <h1>
        },
      },
      cleanOnPaste: true,
    },
    allowInlineStyles: true, // Allow inline styles to transfer them
  };
  async function onUpdateClick() {
    const content = my_content.current.value;
    const cleanData = cleanH1Content(content);
    try {
      await UpdatePageContent(content_id, {
        my_content: cleanData,
      });
      toast.success("Data updated");
    } catch (error) {
      toast.error(error.message);
    }
  }

  const cleanH1Content = (content) => {
    const cleanedContent = content.replace(
      /<h1(.*?)>(.*?)<\/h1>/g,
      (_, attributes, innerContent) => {
        // Create a temporary container for parsing
        const tempDiv = document.createElement("div");
        tempDiv.innerHTML = innerContent;

        // Extract styles from nested tags
        const nestedStyles = Array.from(tempDiv.querySelectorAll("*"))
          .map((el) => el.getAttribute("style") || "")
          .filter(Boolean);

        // Merge all styles
        const uniqueStyles = {};
        nestedStyles
          .join(";")
          .split(";")
          .forEach((style) => {
            const [key, value] = style.split(":").map((s) => s.trim());
            if (key) uniqueStyles[key] = value;
          });

        // Build the final `style` attribute for <h1>
        const finalStyle = Object.entries(uniqueStyles)
          .map(([key, value]) => {
            // Remove unnecessary quotes around font-family values
            if (key === "font-family") {
              value = value.replace(/["']/g, "");
            }
            return `${key}: ${value}`;
          })
          .join("; ");

        // Extract plain text content
        const textContent = tempDiv.textContent || "";

        // Return cleaned <h1> tag
        return `<h1 ${attributes.trim()} style="${finalStyle}">${textContent}</h1>`;
      }
    );
    return cleanedContent;
  };

  return (
    <div className={PageContentEditCss.salesMain}>
      <h2 className={PageContentEditCss.heading}>
        Edit Data for {pageContent.catName || "Home"}
      </h2>

      <div className={PageContentEditCss.innerSale}>
        <div className={PageContentEditCss.EditData}>
          <JoditEditor
            value={pageContent.my_content || ""}
            config={config}
            tabIndex={1}
            ref={my_content}
            // onBlur={(newContent) => setPageContent({ my_content: newContent })}
          />
          <div className={PageContentEditCss.update_btn}>
            <button onClick={onUpdateClick}>Update Data</button>
          </div>
        </div>
      </div>
    </div>
  );
};

// Buy Authentic Face Makeup
export default PageContentEdit;
