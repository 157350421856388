import React, { useRef, useState } from "react";
import { FaEye } from "react-icons/fa";
import { useSelector } from "react-redux";
import Select from "react-select";
import { toast } from "react-toastify";
import { selectBrandList } from "../../../ReduxFiles/Slices/BrandListSlice";
import { selectActiveUserList } from "../../../ReduxFiles/Slices/UserSlice";
import {
  GetIndividualInvoiceWiseReport,
  GetIndividualInvoiceWiseReportProductWise,
} from "../../../api/SalesApi";
import BrandTotalModel from "../../Setting/ManageUser/MyProfile/reports/models/BrandTotalModel";
import ProductDetailsView from "../../Setting/ManageUser/MyProfile/reports/models/ProductDetailsView";
import GobackButton from "../../global/GobackButton";
import style from "./css/report.module.css";
import { ExportExcelFile } from "../../../api/GlobalFunctions";
import { GetEndDate } from "../../MyFunctions/MyFunctions";
import ScrollComp from "../../global/ScrollComp";
export default function ProductWiseReport() {
  const userData = useSelector(selectActiveUserList);
  const [tableData, setTableData] = useState([]);
  const [tableTotal, setTableTotal] = useState({
    quantity_total: 0,
    dis_total: 0,
    grand_total: 0,
  });
  async function onGenarateClick(e) {
    e.preventDefault();
    const start = e.target.start.value;
    const end = e.target.end.value;
    const user_id = e.target.user_id.value;
    const type_id = e.target.type_id.value;
    const start_date = new Date(start).getTime();
    const end_date = GetEndDate(end);

    setTableData([]);
    const query =
      user_id === "50000"
        ? `start_date=${start_date}&end_date=${end_date}&type_id=${type_id}`
        : `start_date=${start_date}&end_date=${end_date}&user_id=${user_id}&type_id=${type_id}`;
    const data = await GetIndividualInvoiceWiseReport(query);
    const ids = data.map((item) => item.id).join();
    if (ids) {
      const productData = await GetIndividualInvoiceWiseReportProductWise(
        `sales_ids=${ids}&type_id=${type_id}`
      );
      // organizeData(productData);
      setTableData(productData);

      const allTotal = productData.reduce(
        (item, curritem) => {
          item.mrp_total +=
            parseInt(curritem.total_quantity) * curritem.mrp_price;
          item.quantity_total += parseInt(curritem.total_quantity);
          item.dis_total += parseInt(curritem.total_dis);
          item.grand_total +=
            curritem.total_quantity * curritem.mrp_price -
            parseInt(curritem.total_dis);
          return item;
        },
        {
          mrp_total: 0,
          quantity_total: 0,
          dis_total: 0,
          grand_total: 0,
        }
      );

      setTableTotal(allTotal);
    } else {
      toast.info("No Data Found");
    }
  }
  const [brandModelData, setBrandModelData] = useState();
  const [detailsData, setDetailsData] = useState();
  function onDetailsView(item) {
    // setDetailsData(item);
    // document.body.style.overflow = "hidden";
    toast.warn("Will Work on this letter. Keep calm!!!");
  }
  const brandList = useSelector(selectBrandList);
  function onBrandwiseClick() {
    if (tableData.length > 0) {
      const brandData = brandList.map((item) => {
        const brandItems = tableData.filter((fitem) => item.id === fitem.brand);
        // console.log(brandItems);

        const brandTotal = brandItems.reduce(
          (mitem, curritem) => {
            mitem.t_discount += parseInt(curritem.total_dis);
            mitem.total_mrp += curritem.mrp_price * curritem.total_quantity;
            mitem.total_tp += curritem.tp_price * curritem.total_quantity;
            mitem.g_total +=
              curritem.mrp_price * curritem.total_quantity -
              (parseInt(curritem.total_dis) || 0);
            return mitem;
          },
          { g_total: 0, total_tp: 0, total_mrp: 0, t_discount: 0 }
        );
        // console.log(brandTotal);

        return {
          ...item,
          ...brandTotal,
        };
      });
      setBrandModelData(brandData);
      document.body.style.overflow = "hidden";
    } else {
      toast.info("No Data Found");
    }
  }

  const formRef = useRef();
  const userRef = useRef();

  function onExcelDownload() {
    if (tableData.length > 0) {
      const form = formRef.current;
      const start = form.start.value;
      const end = form.end.value;
      const userData = userRef.current.getValue();
      const finalData = tableData.map((item, index) => {
        return {
          SL: index + 1,
          "Product Name": item.en_sort_title,
          "TP Price": item.tp_price,
          "MRP Price": item.mrp_price,
          Quantity: parseInt(item.total_quantity),
          "TP Total": item.total_quantity * item.tp_price,
          "MRP Total": item.total_quantity * item.mrp_price,
          "Total Discount": parseInt(item.total_dis),
        };
      });
      ExportExcelFile(
        finalData,
        `${userData[0]?.fullName}-(${start} to ${end}) product wise`
      );
    } else {
      toast.warn("No Data Found to export");
    }
  }

  const filterByType = [
    { label: "Delivered", value: 2 },
    { label: "return", value: 3 },
    { label: "All", value: 1 },
  ];

  return (
    <div className={style.wrapper}>
      {brandModelData && (
        <BrandTotalModel popUp={brandModelData} setPopUp={setBrandModelData} />
      )}
      {detailsData && (
        <ProductDetailsView popUp={detailsData} setPopUp={setDetailsData} />
      )}
      <div className={style.header_part}>
        <GobackButton />
        <h3>Product Wise Report</h3>
      </div>
      <div className={style.holder}>
        <div className={style.form_wrapper}>
          <form onSubmit={onGenarateClick} ref={formRef}>
            <div className={style.input_wrapper}>
              <label>Select Type</label>
              <Select
                options={filterByType}
                defaultValue={filterByType[0]}
                name="type_id"
                required
                // ref={userRef}
              />
            </div>
            <div className={style.input_wrapper}>
              <label>User Select</label>
              <Select
                options={[{ fullName: "All User", id: 50000 }, ...userData]}
                getOptionValue={(option) => `${option.id}`}
                getOptionLabel={(option) => `${option.fullName}`}
                name="user_id"
                required
                ref={userRef}
              />
            </div>
            <div className={style.input_wrapper}>
              <label>Start Date</label>
              <input type="date" name="start" required />
            </div>
            <div className={style.input_wrapper}>
              <label>End Date</label>
              <input type="date" name="end" required />
            </div>
            <div className={style.button_wrapper}>
              <button>Genarate</button>
              <button type="button" onClick={onBrandwiseClick}>
                Brand Wise Report
              </button>
              <button type="button" onClick={onExcelDownload}>
                XLSX
              </button>
            </div>
          </form>
        </div>
        <div className={style.table_wrapper}>
          <table aria-label="product">
            <thead>
              <tr>
                <th>SL</th>
                <th>Product Name</th>
                <th>TP</th>
                <th>MRP</th>
                <th>Quantity</th>
                <th>MRP Total</th>
                <th>Dicount</th>
                <th>Total</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {tableData.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item.en_sort_title}</td>
                    <td>{item.tp_price}</td>
                    <td>{item.mrp_price}</td>
                    <td>{item.total_quantity}</td>
                    <td>{item.total_quantity * item.mrp_price}</td>
                    <td>{parseInt(item.total_dis)}</td>
                    <td>
                      {item.mrp_price * item.total_quantity -
                        parseInt(item.total_dis)}
                    </td>
                    <td>
                      <button onClick={() => onDetailsView(item)}>
                        <FaEye />
                      </button>
                    </td>
                  </tr>
                );
              })}
              <tr className={style.grand_total_wrapper}>
                <td colSpan={4}>Grand Total : </td>
                <td>{tableTotal.quantity_total}</td>
                <td>{tableTotal.mrp_total}</td>
                <td>{tableTotal.dis_total}</td>
                <td>{tableTotal.grand_total}</td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <ScrollComp />
    </div>
  );
}
