import React, { useRef, useState } from "react";
import { FaEye } from "react-icons/fa";
import { useSelector } from "react-redux";
import Select from "react-select";
import { toast } from "react-toastify";
import { selectActiveUserList } from "../../../ReduxFiles/Slices/UserSlice";
import {
  ExportExcelFile,
  ServerTimeToFormateTime,
} from "../../../api/GlobalFunctions";
import {
  GetIndividualInvoiceWiseReport,
  GetIndividualInvoiceWiseReportProductWise,
} from "../../../api/SalesApi";
import { GetEndDate } from "../../MyFunctions/MyFunctions";
import GobackButton from "../../global/GobackButton";
import ScrollComp from "../../global/ScrollComp";
import style from "./css/report.module.css";
import InvoiceDetails from "./models/InvoiceDetails";
export default function InvoiceWiseReport() {
  const userData = useSelector(selectActiveUserList);
  const [tableData, setTableData] = useState([]);
  const [tableTotal, setTableTotal] = useState({
    price_total: 0,
    dc_total: 0,
    grand_total: 0,
    dcc_total: 0,
    cod_total: 0,
    col_total: 0,
    net_total: 0,
    discount_total: 0,
    rctotal: 0,
  });

  async function onGenarateClick(e) {
    e.preventDefault();
    const start = e.target.start.value;
    const end = e.target.end.value;
    const user_id = e.target.user_id.value;
    const type_id = e.target.type_id.value;
    const start_date = new Date(start).getTime();
    const end_date = GetEndDate(end); //new Date(end).getTime();
    setTableData([]);
    const query =
      user_id === "50000"
        ? `start_date=${start_date}&end_date=${end_date}&type_id=${type_id}`
        : `start_date=${start_date}&end_date=${end_date}&user_id=${user_id}&type_id=${type_id}`;

    const data = await GetIndividualInvoiceWiseReport(query);
    /*
     <td>{item.total_orprice}</td>
                    <td>{item.discount}</td>
    */
    const finaldata = data.map((item) => {
      return {
        ...item,
        total_orprice: item.fullReturn ? 0 : item.total_orprice,
        deliveryCharge: item.fullReturn ? 0 : item.deliveryCharge,
        discount: item.fullReturn
          ? 0
          : item.total_orprice -
            item.total_price +
            parseInt((item.total_orprice * item.discountPercent) / 100),
      };
    });
    // console.log(finaldata);
    // setTableTotal({
    //   price_total: 0,
    //   dc_total: 0,
    //   grand_total: 0,
    //   dcc_total: 0,
    //   cod_total: 0,
    //   col_total: 0,
    //   net_total: 0,
    //   discount_total: 0,
    // });
    setTableData(finaldata);
    const allTotal = finaldata.reduce(
      (item, curritem) => {
        item.price_total += parseInt(curritem.total_orprice);
        item.dc_total += curritem.deliveryCharge;
        item.grand_total +=
          curritem.deliveryCharge + parseInt(curritem.total_price);
        item.dcc_total += curritem.deliveryCost;
        item.cod_total += curritem.codSSLCharge;
        item.col_total += curritem.receiveAmmount || 0;
        item.discount_total += curritem.discount || 0;
        item.rctotal += curritem.return_charge || 0;
        item.net_total +=
          curritem.deliveryCharge +
          parseInt(curritem.total_orprice) -
          curritem.deliveryCost -
          curritem.codSSLCharge -
          curritem.discount -
          curritem.return_charge;
        return item;
      },
      {
        price_total: 0,
        dc_total: 0,
        grand_total: 0,
        dcc_total: 0,
        cod_total: 0,
        col_total: 0,
        net_total: 0,
        discount_total: 0,
        rctotal: 0,
      }
    );
    setTableTotal(allTotal);
  }
  const [detailInvoice, setDetailInvoice] = useState();
  async function onDetailViewClick(item) {
    const productData = await GetIndividualInvoiceWiseReportProductWise(
      `sales_ids=${item.id}`
    );
    setDetailInvoice({ ...item, products: productData });
    // console.log(productData);
  }
  const formRef = useRef();
  const userRef = useRef();
  function onExportClick() {
    if (tableData.length > 0) {
      const form = formRef.current;
      const start = form.start.value;
      const end = form.end.value;
      const userData = userRef.current.getValue();
      // console.log(userData, start, end);
      const finalData = tableData.map((item, index) => {
        return {
          SL: index + 1,
          Invoice: item.id,
          // "Date & Time": ServerTimeToFormateTime(item.created_date),
          // Status: item.status,
          Name: item.name,
          // Address: item.address,
          Price: item.total_orprice,
          Discount: item.discount,
          "Delivery Charge": item.deliveryCharge,
          "Delivery Charge (Cost)": item.deliveryCost,
          COD: item.codSSLCharge,
          Collection: item.receiveAmmount || 0,
          "Net Amount":
            item.deliveryCharge +
            parseInt(item.total_orprice) -
            item.deliveryCost -
            item.codSSLCharge -
            item.return_charge -
            item.discount,

          // CRM: item.userName,
          TrasactionBy: item.transactionType,
        };
      });
      ExportExcelFile(
        finalData,
        `${userData[0]?.fullName}-(${start} to ${end})`
      );
    } else {
      toast.info("No Data Found to Export!");
    }

    // ExportExcelFile()
  }

  const filterByType = [
    { label: "All", value: 1 },
    { label: "Delivered", value: 2 },
    { label: "return", value: 3 },
  ];

  return (
    <div className={style.wrapper}>
      {detailInvoice && (
        <InvoiceDetails popUp={detailInvoice} setPopUp={setDetailInvoice} />
      )}
      <div className={style.header_part}>
        <GobackButton />
        <h3>Invoice Wise Report</h3>
      </div>
      <div className={style.holder}>
        <div className={style.form_wrapper}>
          <form onSubmit={onGenarateClick} ref={formRef}>
            <div className={style.input_wrapper}>
              <label>Select Type</label>
              <Select
                options={filterByType}
                defaultValue={filterByType[0]}
                name="type_id"
                required
                // ref={userRef}
              />
            </div>
            <div className={style.input_wrapper}>
              <label>User Select</label>
              <Select
                options={[{ fullName: "All User", id: 50000 }, ...userData]}
                getOptionValue={(option) => `${option.id}`}
                getOptionLabel={(option) => `${option.fullName}`}
                name="user_id"
                required
                ref={userRef}
              />
            </div>
            <div className={style.input_wrapper}>
              <label>Start Date*</label>
              <input type="date" name="start" required />
            </div>
            <div className={style.input_wrapper}>
              <label>End Date*</label>
              <input type="date" name="end" required />
            </div>
            <div className={style.button_wrapper}>
              <button>Genarate</button>
              <button type="button" onClick={onExportClick}>
                Export XLSX
              </button>
            </div>
          </form>
        </div>
        <div className={style.table_wrapper}>
          <table aria-label="invoice">
            <thead>
              <tr>
                <th>SL</th>
                <th>Invoice</th>
                <th>Status</th>
                <th>Date</th>
                <th>Name</th>
                {/* <th>Address</th> */}
                <th>Price</th>
                <th>Discount</th>
                <th>DC</th>
                <th>DC Charge</th>
                <th>COD</th>
                <th>R. Charge</th>
                <th>Collect</th>
                <th>Net</th>
                {/* <th>Total</th> */}
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {tableData.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item.id}</td>
                    <td>{item.status}</td>
                    <td>{ServerTimeToFormateTime(item.created_date)}</td>
                    <td>{item.name}</td>
                    {/* <td>{item.address}</td> */}
                    <td>{item.total_orprice}</td>
                    <td>{item.discount}</td>
                    <td>{item.deliveryCharge}</td>
                    <td>{item.codSSLCharge}</td>
                    <td>{item.deliveryCost}</td>
                    <td>{item.return_charge}</td>
                    <td>{item.receiveAmmount || 0}</td>

                    <td>
                      {`${(
                        item.deliveryCharge +
                        parseInt(item.total_orprice) -
                        item.deliveryCost -
                        item.codSSLCharge -
                        item.return_charge -
                        item.discount
                      ).toFixed(2)}`}
                    </td>
                    <td>
                      <button onClick={() => onDetailViewClick(item)}>
                        <FaEye />
                      </button>
                    </td>
                  </tr>
                );
              })}
              <tr className={style.grand_total_wrapper}>
                {/* <td colSpan={5}></td> */}
                <td colSpan={5}>Grand Total : </td>
                <td>{parseInt(tableTotal.price_total)}</td>
                <td>{parseInt(tableTotal.discount_total)}</td>
                <td>{parseInt(tableTotal.dc_total)}</td>
                <td>{parseInt(tableTotal.dcc_total)}</td>
                <td>{parseInt(tableTotal.cod_total)}</td>
                <td>{parseInt(tableTotal.rctotal)}</td>
                <td>{parseInt(tableTotal.col_total)}</td>
                <td>{parseInt(tableTotal.net_total)}</td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <ScrollComp />
    </div>
  );
}
